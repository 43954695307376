.aside-minimized {
  width: 80px;
}

.aside-minimized .aside-logo {
  display: flex;
  justify-content: center;
}

.aside-minimized .menu-title,
.aside-minimized .menu-icon {
  display: none;
}

.aside-minimized .menu-title {
  visibility: hidden;
}

.aside-minimized .menu-icon {
  visibility: visible;
}

.aside-minimized .menu-title,
.aside-minimized .menu-link:hover .menu-title {
  display: none;
}

.aside.aside-minimized {
  min-width: 80px;
}

.aside.aside-minimized .aside-logo {
  display: flex;
  justify-content: center;
}

.aside.aside-minimized .aside-logo img {
  width: 30px;
  height: 30px;
  transition: all 0.3s ease-in-out;
}

.aside.aside-minimized .aside-toggle {
  justify-content: center;
}

.aside.aside-minimized .aside-logo-minimized img {
  opacity: 0;
  width: 0;
}

.aside.aside-minimized .aside-logo-minimized {
  width: 30px;
  height: 30px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

